
import getPageQuery from '~/queries/getPageQuery'

export default {
  data() {
    return {
      page: null
    }
  },
  // Note: fetch is called on server-side when rendering the route,
  // and on client-side when navigating.
  async asyncData({ store, error, route, i18n, $sanity }) {
    const { locale } = i18n;
    const {
      params: { pathMatch }
    } = route;
    const urlParts = pathMatch.split('/');
    const activeSlug = urlParts.length && urlParts[urlParts.length - 1];
    const isRootPage = activeSlug === ''
      || i18n.locales.some((appLocale) => appLocale.code === activeSlug);
    const slug = isRootPage
      ? '/'
      : activeSlug;
    const pageQuery = getPageQuery(slug, locale);

    try {
      const page = await $sanity.fetch(pageQuery);
      const isPageEmpty = page == null || Object.keys(page).length === 0;

      if (isPageEmpty) {
        error({
          statusCode: 404,
          message: 'Page not found'
        });
      }

      return {
        page
      };
    } catch (err) {
      if (process.server) {
        error({
          statusCode: 404,
          message: 'Page not found'
        });
      }
    }
  },

  // watch: {
  //   '$fetchState.pending': function (val) {
  //     this.$store.commit('ui/toggleLoader', val)
  //   }
  // },
  async mounted() {
    // Workaround to patch the scrolling behaviour.
    // At the moment, we have ServiceSlider.vue that listens for
    // scroll event and it interferes with our jumplink features.
    // This thing happens only when we have a ServiceSlider component in a page.
    const { hash, path } = this.$route
    setTimeout(() => {
      if (hash !== '' && path === '/') {
        this.scrollTo(hash)
      }
    }, 1000)
  },

  // activated() {
  //   if (this.$fetchState.pending) {
  //     return
  //   }

  //   this.$store.commit('ui/toggleLoader', false)
  // },
  head() {
    return this.generatePageMetaTags(this.page)
  }
}
