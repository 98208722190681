import { groq } from "@nuxtjs/sanity";
/**
 * In this query, we retrieve a page and its translations
 */

export default function getPageQuery(slug, locale) {

  // Query for all pages
  let defaultQuery = `_type=="page" &&
  live==true &&
  slug.current=="${slug}" &&
  __i18n_lang=="${locale}_CH" &&
  !(_id in path("drafts.**"))`;

  // Query for the homepage. We need to do that because homepage as no slug by default
  if (slug == '/') {
    defaultQuery = `_type=="page" &&
    live==true &&
    home==true &&
    __i18n_lang=="${locale}_CH" &&
    !(_id in path("drafts.**"))`
  }

  return groq`*[${defaultQuery}][0] {
  ...,
  content{
    ...,
    sections[]{
      ...,
      reference,
      "ref":reference->
    }
  },
  !(_id match "__i18n_") => {
    "translations": {
      "de": {
        "slug": slug.current,
      },
      "fr":__i18n_refs[lang == "fr_CH"]{
      "slug":ref->.slug.current,
    }[0],
    }
  },
  (_id match "*__i18n_*") => {
    "translations":
    *[references(^._id) && __i18n_lang =="de_CH"]{
      "de":{
         "slug":slug.current,
       },
      "fr":(__i18n_refs[].ref->{
          __i18n_lang,
          "slug":slug.current
        })[__i18n_lang=="fr_CH"][0], 
    }[0]
  }
}`;
}

